<template>
    <div id="assetCategoriesList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="assetCategories"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="asset_categoryListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link :to="{ name: 'newAssetCategory' }" id="assets_createNewAssetCategoryButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("asset_createNewCategory") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row" v-show="isDisplayed">
                                <!-- begin:: Block for default Asset Categories -->
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <i class="kt-font-brand flaticon-list-2"></i>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("asset_defaultCategoryList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByName')" @keyup="onSearch" v-model="searchedValue" id="assets_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="DEFAULT_ASSET_CATEGORIES" mainTextKey="asset_defaultCategoryNotFound" subTextKey="asset_defaultCategoryNotFoundSubLabel" imgName="empty_assetcategory.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="defaultCategories_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                                <!--end:: Block for default Asset Categories -->
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            defaultCategoriesTable: null,
            isEmptyList: false,
            isDisplayed: false,
            searchedValue: ""
        };
    },
    created: function() {
        console.log("Component(AssetCategories)::created() - called");
        // Load all asset categories of site from backend and store the result in assetCategories store.
        if (this.createAnotherAssetCategory) {
            this.$router.push({ name: "newAssetCategory" });
        } else {
            this.getSiteAssetCategories(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(AssetCategories)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(AssetCategories)::destroyed() - called");
        this.resetCategoriesState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        siteAssetCategories: function(categories) {
            console.log("Component(AssetCategories)::watch(siteAssetCategories) called");
            if (categories) {
                this.initKtDataTable(categories);
            }
        },
        user: function(user) {
            console.log("Component(AssetCategories)::watch(user) called with :", user);
            if (this.siteAssetCategories && user) {
                this.initKtDataTable(this.siteAssetCategories);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteAssetCategories", "currentSite", "user", "createAnotherAssetCategory"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteAssetCategories", "resetCategoriesState", "showDeleteModal"]),

        onSearch() {
            if (this.defaultCategoriesTable) {
                this.defaultCategoriesTable.search(this.searchedValue, "translateName");
            }
        },

        onEditAssetCategory: function(assetCategoryId) {
            this.$router.push({ name: "editAssetCategory", params: { siteId: this.siteId, assetCategoryId: assetCategoryId } });
        },

        buildDatatableData(categoryList) {
            // Prepare data before init datatable
            let data = categoryList.map((category) => {
                let finalCategory = {...category};
                // Translate category name
                if (category.isDefault && category.name) {
                    finalCategory.translateName = i18n.t(category.name);
                } else {
                    finalCategory.translateName = category.name;
                }
                return finalCategory;
            });
            return data;
        },

        getOptionsOfDatable: function(categoryList) {
            const dataSource = this.buildDatatableData(categoryList);
            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(dataSource)
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: 0,
                columns: [
                    {
                        field: "translateName",
                        title: i18n.t("asset_categoryName"),
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        template: function(row) {
                            return '\
                                <div class="kt-user-card-v2">\
                                    <div class="kt-user-card-v2__details openResourceLink">\
                                        <a class="editAssetCategoryButton kt-link kt-user-card-v2__name" data-categoryid="' + row.id + '" href="javascript:;">' + row.translateName + "</a>\
                                    </div>\
                                </div>\
                            ";
                        }
                    },
                    {
                        field: "icon",
                        title: i18n.t("asset_categoryIcon"),
                        sortable: false,
                        textAlign: "center",
                        template: function(row) {
                            if (row.isDefault) {
                                let iconUrl = "/assets/categories/" + row.name + "/Icon.png";
                                return '<img class="iconPreview" src="' + iconUrl + '" /></img>';
                            } else {
                                let currentIcon = row.iconImg;
                                return '<div class="iconPreviewCustom" style="background-image: url(' + currentIcon + ')"></div>';
                            }
                        }
                    },
                    {
                        field: "iconSize",
                        title: i18n.t("asset_categoryIconSize"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.iconSize) {
                                return i18n.t(row.iconSize);
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            if (row.isDefault) {
                                return (
                                    `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                    i18n.t("common_edit") +
                                    `" type="button" data-categoryid="` +
                                    row.id +
                                    `" class="btn btn-outline-brand btn-sm btn-icon editAssetCategoryButton">
                                            <i class="la la-pencil"></i>
                                    </button>
                                `
                                );
                            } else {
                                return (
                                    `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                    i18n.t("common_edit") +
                                    `" type="button" data-categoryid="` +
                                    row.id +
                                    `" class="btn btn-outline-brand btn-sm btn-icon editAssetCategoryButton">
                                            <i class="la la-pencil"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                    i18n.t("common_delete") +
                                    `" type="button" data-categoryid="` +
                                    row.id +
                                    `" data-assetname="` +
                                    row.name +
                                    `" class="btn btn-outline-danger btn-sm btn-icon deleteAssetCategoryButton">
                                            <i class="la la-trash"></i>
                                    </button>
                                `
                                );
                            }
                        }
                    }
                ]
            };
            return options;
        },

        registerEventsOnSiteAssetCategoriesTable(categorySelector) {
            var self = this;
            $(categorySelector).on("kt-datatable--on-layout-updated", () => {
                $(".deleteAssetCategoryButton")
                    .off()
                    .on("click", function() {
                        let assetCategoryId = $(this).attr("data-categoryid");
                        let assetCategoryName = $(this).attr("data-assetname");
                        console.log("Component(AssetCategories)::registerEventsOnSiteAssetCategoriesTable(deleteAssetCategoryButton) clicked. AssetId = " + assetCategoryId);
                        self.showDeleteModal({
                            textContent: i18n.t("asset_deleteCategoryConfirmText", { assetCategoryName: assetCategoryName }),
                            actionName: "DELETE_ASSET_CATEGORY",
                            params: { siteId: self.siteId, assetCategoryId: assetCategoryId }
                        });
                    });
                $(".editAssetCategoryButton")
                    .off()
                    .on("click", function() {
                        let assetCategoryId = $(this).attr("data-categoryid");
                        console.log("Component(AssetCategories)::registerEventsOnSiteAssetCategoriesTable(editAssetCategoryButton) clicked. AssetId = " + assetCategoryId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditAssetCategory(assetCategoryId);
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },

        initKtDataTable(categories) {
            const defaultOptions = this.getOptionsOfDatable(categories);
            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.defaultCategoriesTable) {
                $("#defaultCategories_datatable")
                    .KTDatatable()
                    .destroy();
            }

            // According to source default list length, show datatable or empty list icon+text
            if (defaultOptions.data.source && defaultOptions.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.defaultCategoriesTable = $("#defaultCategories_datatable").KTDatatable(defaultOptions);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteAssetCategoriesTable("#defaultCategories_datatable");
            } else {
                // There is no data => Show empty list icon+text
                // No table process: Comment lines below because table destroy previously
                this.isEmptyList = true;
            }
            this.isDisplayed = true;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>

<style>
.iconPreview {
    height: 45px;
    width: 45px;
}

.iconPreviewCustom {
    margin: 0 auto;
    height: 45px;
    width: 45px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 300px;
}
</style>
